<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            A fizetett trollhadseregek nyomában
          </h2>
          <p class="hashText">- Mit is rejt a kommentszekció?</p>
        </b-col>
      </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" lg="6">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                Te is tapasztaltad már, hogy egyre több a gyűlölködő komment a közösségi médiában és kétszer is meg kell nézni, hogy milyen hírt fogadunk el valósnak és milyen forrást ismerünk el hitelesnek? </p>

                <p class="text">
                Gondolkodtál már azon, hogy a sok rágalmazás félrevezetés mögött bizonyos esetekben fizetett alkalmazottak állnak? Ma már kifejezett szervezetek működnek a megtévesztés zászlaja alatt, ilyen például a trollhadsereg is. Cikkünkből megtudhatod, mit rejt ez az elnevezés és miért fontos, hogy felismerd az általuk terjesztett tartalmakat.
                </p>
            </div>
            </b-col>
          <b-col cols="12" lg="6">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                <b>Mi is az a trollhadsereg?</b></p>

                <p class="text">
                A fizetett trollhadseregek olyan csoportokat jelentenek, amelyeket pénzért bérelnek fel leginkább vállalkozások, vagy politikai pártok céljaik elérése érdekében. Az ilyen csoportok tagjai, akiket "bértrolloknak" neveznek, gyakran a közösségi média platformokon és fórumokon terjesztik a hamis vagy megtévesztő információkat, vagy keltik egy bizonyos személy rossz hírét.<br><br>Rágalmazásuk következtében karrierek dőlhetnek be, életek mehetnek tönkre, pánikkeltésük nyomán pedig nemcsak a vásárlási döntéseinket képesek befolyásolni, de a biztonságérzetünkre is hatással vannak.
                </p>

                <p class="text"><b>Hogyan ismerd fel a működésüket?</b></p>

                <p class="text">
                    Nem mindig egyszerű tetten érni működésüket, mert álprofilok mögé bújva egy egész közösség ténykedését kellene felfedeznünk kommentek és megosztások hálójában. Hoztunk neked pár tippet, amik segítenek abban, hogy ne dőlj be mesterkedésüknek!
                </p>
            </div>
            </b-col>
            <b-col cols="12" lg="10" class="side-gap">
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text"><b>Következetes és szervezett támadások</b><br>A fizetett trollhadseregek gyakran koordinált támadásokat indítanak ugyanazon a témán vagy célponton belül, amelyek során nagy mennyiségű hasonló kommentet vagy negatív tartalmat osztanak meg. Ha észreveszel egy kupacban sok, hirtelen felbukkanó negatív hozzászólást vagy trollkodást egy adott témában, az lehet egy ilyen szervezet tevékenysége.</p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text"><b>Hamis vagy generikus profilok</b><br> A bértrollok gyakran hamis profilokat használnak, amelyeken nincs valós személyes információ, kevés követővel rendelkeznek és tevékenységnaplójuk sem egy átlagos felhasználó benyomását kelti. Az ilyen profilokon általában nincs aktív tevékenység, és csak a hamis vagy rágalmazó tartalmak terjesztésére használják őket.</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text"><b>Rendkívül negatív vagy provokatív kommentek</b><br> A bértroll csoportok célja általában a viták kirobbantása vagy fokozása, a hangulat rombolása és az online közösségek destabilizálása. Így, ha valaki rendkívül negatív, indulatos vagy provokatív kommenteket oszt meg, amelyek túlzó vagy alaptalan vádakkal, gyűlöletbeszéddel vagy más emberek megszégyenítésével járnak, hallgass a benned felmerülő kételyre. Az ilyen személyek tevékenységét jelentsd és kerüld a velük való érintkezést!</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">4</span>
                  <p class="text"><b>Azonos reakciók és sablonos válaszok</b><br> A trollkodók gyakran sablonos vagy szokványos válaszokat adnak, amelyekben visszatérő szófordulatokat, támadó nyelvezetet vagy ellenséges hangnemet használnak. Az ilyen típusú válaszokkal rendelkező profilok általában nem hajlandóak érdemi vitára vagy párbeszédre. Ha egy komment hangneme a fent leírtakkal egyezik, de a rá érkező kérdésekre, vagy a vele kapcsolatos ellenérvek felsorakoztatására nem érkezik érdemi reakció, szintén jó esély van rá, hogy egy bértroll sorait olvasod.</p> 
                </li>
              </ul>
            </p>
            </b-col>
            <b-col cols="12" lg="6" class=" side-gap">
            <p class="text">
                <b>Mit tehetsz ellenük?</b>
              <ul>
                <li class="py-3 d-sm-flex">
                  <p class="text"><b>1.Jelentsd a tevékenységüket!</b><br>Ha bértrollok tartalmait vagy más visszaélési formát látsz, jelentsd azt a megfelelő platformnak vagy a közösségi média szolgáltatónak. A közösségi alapelvek mentén minden platform igyekszik fellépni a trollkodás és a visszaélések ellen. Segítsd ezek moderációját azzal, hogy nem reagálsz a tartalmakra, hozzászólásokra, de jelented azokat!</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <p class="text"><b>2.Ne terjeszd az igét!</b><br>A fizetett troll hadseregek célja általában a reakciók és a provokációk kiváltása. Ha találkozol egy trollkodóval, próbálj meg nem reagálni a kommentre, vagy tartalomra, akkor sem, ha a jószándék vezérel és ezzel a fellépéseddel szeretnél kiállni valaki mellett. Ne ossz meg provokatív tartalmakat és ne terjessz tovább olyan információkat, amelyeket nem ellenőriztél!</p>
                </li>
              </ul>
            </p>
        </b-col>
        <b-col cols="12" lg="6">
              <img src="images/troll.png" class="w-100 margin-top-sm-44 mb-4"/>
          </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "A fizetett trollhadseregek nyomában", 
        desc: "A fizetett trollhadseregek olyan csoportokat jelentenek, amelyeket pénzért bérelnek fel leginkább vállalkozások, vagy politikai pártok céljaik elérése érdekében. ", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "A fizetett trollhadseregek nyomában",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "A fizetett trollhadseregek olyan csoportokat jelentenek, amelyeket pénzért bérelnek fel leginkább vállalkozások, vagy politikai pártok céljaik elérése érdekében. "        
    })
  }
}
</script>